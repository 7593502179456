// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import { Russian } from "flatpickr/dist/l10n/ru.js"

export default class extends Controller {
  static targets = ['calendar', 'cinemaSelect']

  connect() {
    this.initCalendar()

    // $(document).on('submit', 'form[data-pjax]', function(event) {
    //   $.pjax.submit(event, '#pjax-container', {
    //     scrollTo: false,
    //     timeout: 5000,
    //     fragment: '#pjax-container'
    //   })
    // });
    // console.log('afisha-filter', this.calendarTarget)
  }

  initCalendar() {
    flatpickr.l10ns.ru.months.longhand = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ]

    flatpickr.l10ns.ru.weekdays.longhand = [
      "воскресенье",
      "понедельник",
      "вторник",
      "среда",
      "четверг",
      "пятница",
      "суббота",
    ]

    flatpickr(this.calendarTarget, {
      dateFormat: 'd.m.Y',
      locale: Russian,
      wrap: true,
      altInput: true,
      altFormat: 'j F, l',
      altInputClass: 'form-control-plaintext h4 font-weight-normal afisha--calendar-input',
      disableMobile: true,
      onValueUpdate: function(selectedDates, dateStr, instance) {
        console.log('onValueUpdate', dateStr)
      }.bind(this),
      onChange: function(selectedDates, dateStr, instance) {
        this.updateAltInputSize()
        // $(this.element).submit()
        // console.log('onChange', dateStr)
      }.bind(this)
    })

    this.updateAltInputSize()
  }

  updateAltInputSize() {
    const val = $(this.calendarTarget).find('.afisha--calendar-input').val()
    const size = val.length
    // console.log(val, size)
    $(this.calendarTarget).find('.afisha--calendar-input').attr('size', size - 1)
  }

  cityChanged(e) {
    console.log('city changed', e)
    this.resetCinemaSelection()
    this.submitForm()
  }

  cinemaChanged(e) {
    console.log('cinemaChanged', e)
    this.submitForm()
  }

  priceChanged(e) {
    this.submitForm()
  }

  dateChanged(e) {
    // this.resetPriceSelection()
    this.submitForm()
  }

  resetCinemaSelection() {
    $(this.cinemaSelectTarget).selectpicker('val', '')
  }

  // resetPriceSelection() {
  //   $(this.priceInputTarget).val($(this.priceInputTarget).attr('max'))
  // }

  submitForm() {
    $(this.element).submit()
  }
}
