// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'root', 'page' ]

  connect() {
    console.log('push navigation', this.pageTargets.length)
    if (this.pageTargets.length == 1) {
      this.pageTargets[0].classList.add('active')
      this.pageShown()
    }
  }

  show(e) {
    e.preventDefault()
    const index = e.target.getAttribute('data-index')
    this.pageTargets.forEach((el, i) => {
      el.classList.toggle('active', i == index)
    })
    const self = this
    $(this.element).animate({
      left: '-100%'
    }, function(e) {
      self.pageShown()
    })
  }

  pageShown() {
    this.rootTarget.classList.toggle('d-none', true)
    $(this.element).css('left', '0%')
  }

  back(e) {
    e.preventDefault()
    this.rootTarget.classList.toggle('d-none', false)
    $(this.element).css('left', '-100%')
    const self = this
    $(this.element).animate({
      left: '0%'
    }, function() {
      self.rootShown()
    })
  }

  rootShown() {
    this.pageTargets.forEach((el, i) => {
      el.classList.toggle('active', false)
    })
  }
}
