import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).owlCarousel({
      items: 1,
      dots: true,
      nav: true,
      autoHeight: true
    })
  }
}
