import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).owlCarousel({
      items: 1,
      dots: false,
      nav: false,
      autoHeight: false
    })
  }

  next(e) {
    e.preventDefault()
    var owl = $(this.element);
    owl.trigger('next.owl.carousel');
  }

  prev(e) {
    e.preventDefault()
    var owl = $(this.element);
    owl.trigger('prev.owl.carousel');
  }
}
