// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "output" ]
  static values = {
    "url": String
  }

  connect() {
    console.log("stills controller", this.urlValue)

    $.rails.ajax({
      url: this.urlValue,
      success: function(data) {
        this.dataLoaded(data)


      }.bind(this)
    })
  }

  dataLoaded(data) {
    this.element.innerHTML = data

    $(this.element).owlCarousel({
      items: 1,
      nav: true,
      loop: true
    })
  }
}
